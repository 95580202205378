import { IAccessRight } from '@interfaces/access-rights/access-right.interface';

export namespace ManageTrademarksActions {
  export class SetSelectedColumns {
    static readonly type = '[MANAGETM] Set selected columns';
    constructor(public selectedColumns: Array<number>) {}
  }

  export class SetSelectedQuickSearch {
    static readonly type = '[MANAGETM] Set quick search key';
    constructor(public data: string) {}
  }

  export class SetTmDetailsIrn {
    static readonly type = '[TRADEMARKS] Set Trademark Details IRN';
    constructor(public irn: string) {}
  }

  export class SetTmUserAccessRights {
    static readonly type = '[TRADEMARKS] Set Trademark AccessRights';
    constructor(public userAccessRight: IAccessRight) {}
  }

  export class SetListView {
    static readonly type = '[TRADEMARKS] Set List View';
    constructor() {}
  }

  export class SetStatisticsView {
    static readonly type = '[TRADEMARKS] Set Statistics View';
    constructor() {}
  }
}
