import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WAngularIndependentModule, WButtonModule } from '@wipo/w-angular';

@Component({
  selector: 'emad-customer-support-bar',
  templateUrl: 'customer-support-bar.component.html',
  standalone: true,
  imports: [CommonModule, WButtonModule, TranslateModule, WAngularIndependentModule],
  providers: [TranslateService],
})
export class CustomerSupportBarComponent {
  @Input() public customerSupportEmail: string | null = null;
  @Output() public endSessionEvent = new EventEmitter<void>();

  public onEndSession(): void {
    this.endSessionEvent.emit();
  }
}
