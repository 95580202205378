import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IAccessRight } from '@interfaces/access-rights/access-right.interface';
import { ManageTrademarksActions } from '@store/manage-trademarks/actions/manage-trademarks.action';
import { ManageTmsFiltersState } from '@store/sidebar-filters/states/manage-trademarks-filters.state';

export interface ManageTrademarksModel {
  count: number;
  data: string[];
  listView: boolean;
  searchKey: string;
  selectedColumns: number[];
  statisticsView: boolean;
  tmDetailsIrn: string;
  tmUserAccessRight: IAccessRight;
}

@State<ManageTrademarksModel>({
  name: 'manageTrademarks',
  defaults: {
    count: 0,
    data: [],
    listView: true,
    searchKey: '',
    selectedColumns: [],
    statisticsView: false,
    tmDetailsIrn: '',
    tmUserAccessRight: { canEdit: false, canDelegate: false },
  },
  children: [ManageTmsFiltersState],
})
@Injectable()
export class ManageTrademarksState {
  @Selector()
  public static getSelectedColumns(state: ManageTrademarksModel): number[] {
    return state.selectedColumns;
  }

  @Selector()
  public static getSelectedQuickSearch(state: ManageTrademarksModel): string {
    return state.searchKey;
  }

  @Selector()
  public static getTmDetailsId(state: ManageTrademarksModel): string {
    return state.tmDetailsIrn;
  }

  @Selector()
  public static getTmUserAccessRight(state: ManageTrademarksModel): IAccessRight {
    return state.tmUserAccessRight;
  }

  @Selector()
  public static getListView(state: ManageTrademarksModel): boolean {
    return state.listView;
  }

  @Selector()
  public static getStatisticsView(state: ManageTrademarksModel): boolean {
    return state.statisticsView;
  }

  @Action(ManageTrademarksActions.SetSelectedColumns)
  public setSelectedColumns(context: StateContext<ManageTrademarksModel>, actionParams: any): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      selectedColumns: actionParams['selectedColumns'],
    });
  }

  @Action(ManageTrademarksActions.SetSelectedQuickSearch)
  public setSearchKey(context: StateContext<ManageTrademarksModel>, searchParam: string): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      searchKey: searchParam,
    });
  }

  @Action(ManageTrademarksActions.SetTmDetailsIrn)
  public setTmDetailsId(context: StateContext<ManageTrademarksModel>, actionParams: any): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      tmDetailsIrn: actionParams['irn'],
    });
  }

  @Action(ManageTrademarksActions.SetTmUserAccessRights)
  public setTmUserAccesRights(
    context: StateContext<ManageTrademarksModel>,
    actionParams: any,
  ): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      tmUserAccessRight: actionParams['userAccessRight'],
    });
  }

  @Action(ManageTrademarksActions.SetListView)
  public setListView(context: StateContext<ManageTrademarksModel>): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      listView: true,
      statisticsView: false,
    });
  }

  @Action(ManageTrademarksActions.SetStatisticsView)
  public setStatisticsView(context: StateContext<ManageTrademarksModel>): void {
    const state: ManageTrademarksModel = context.getState();
    context.setState({
      ...state,
      listView: false,
      statisticsView: true,
    });
  }
}
