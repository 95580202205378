import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { WatchlistFiltersActions } from '../actions/watchlist-filters.actions';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface WatchlistFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  trademarkIrnsList: string[];
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
}

@State<WatchlistFiltersStateModel>({
  name: 'watchlistFilters',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    trademarkIrnsList: [],
    filtersSidebarCollapsedState: true,
    selectedFilters: {},
  },
})
@Injectable()
export class WatchlistFiltersState extends BaseFiltersState<WatchlistFiltersStateModel> {

  @Selector()
  static getTrademarksIrns(state: WatchlistFiltersStateModel): string[] {
    return state.trademarkIrnsList;
  }
  
  @Selector()
  public static getSelectedFilters(state: WatchlistFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  public static getFiltersSidebarCollapsedState(state: WatchlistFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Selector()
  public static getFiltersSections(state: WatchlistFiltersStateModel): IFilterSection[] {
    return state.filtersSections;
  }

  /**
   * Whenerver the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(WatchlistFiltersActions.SetTrademarksIrns)
  setTrademarksIds(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetTrademarksIrns,
  ) {
    context.patchState({
        trademarkIrnsList: action.trademarkIrns,
    });
  }

  @Action(WatchlistFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  @Action(WatchlistFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  @Action(WatchlistFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  @Action(WatchlistFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }


  @Action(WatchlistFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }
}
