import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import data from '../../../../assets/mock-data/emadrid/users.json';
import { IEMadridUserService } from './i-emadrid-user.interface';
import { IEMadridUser } from '@interfaces/common/emadrid-user.interface';

@Injectable({
  providedIn: 'root',
})
export class MockEMadridUserService implements IEMadridUserService {
  constructor() {
    console.log('MockEMadridUserService');
  }

  findEMadridUserById(id: string): Observable<IEMadridUser | undefined> {
    const user = data.users.find((user: IEMadridUser) => user.Id === id);
    return of(user);
  }

  findEMadridUserByEmail(email: string): Observable<IEMadridUser | undefined> {
    const user = data.users.find((user: IEMadridUser) => user.email === email);
    return of(user);
  }

  findEMadridUserByName(name: string): Observable<IEMadridUser | undefined> {
    const user = data.users.find(
      (user: IEMadridUser) =>
        user.lastname === name ||
        user.firstname === name ||
        user.firstname + ' ' + user.lastname === name,
    );
    return of(user);
  }
}
