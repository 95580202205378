import { Injectable } from '@angular/core';
import { ScriptLoader } from 'src/shared/helper/script-loader';
import { from, mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(private scriptLoader: ScriptLoader) {}

  initializeAppFactory(): Observable<any> {
    return this.loadWipoNavbar(); // Returning an Observable
  }

  static createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

  loadWipoNavbar(): Observable<any> {
    const wipoWebComponentScriptsBasePath = environment.wipoNavBarUrl;

    const scriptUrls = [
      `${wipoWebComponentScriptsBasePath}/polyfills/webcomponents-loader.js`,
      `${wipoWebComponentScriptsBasePath}/wipo-init/wipo-init-ipportal.js`,
      `${wipoWebComponentScriptsBasePath}/wipo-navbar/wipo-navbar.js`,
    ];

    return from(scriptUrls).pipe(mergeMap((url) => this.scriptLoader.loadScript(url, true)));
  }
}
