import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '@constants/app-routes.constants';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: AppRoutes.APP.EMADRID,
        loadComponent: () =>
          import('./modules/e-madrid/e-madrid.component').then((m) => m.EMadridComponent),
      },
      {
        path: AppRoutes.APP.FILEAPP,
        loadChildren: () => import('./modules/fileapp/fileapp.module').then((m) => m.FileappModule),
      },
      {
        path: AppRoutes.APP.FINDMONIT,
        loadChildren: () =>
          import('./modules/findmonit/findmonit.module').then((m) => m.FindmonitModule),
      },
      {
        path: AppRoutes.APP.MANAGE,
        loadChildren: () =>
          import('./modules/managetm/managetm.module').then((m) => m.ManageTmModule),
      },
      {
        path: AppRoutes.APP.POC,
        loadChildren: () =>
          import('./_POC/typeahead/typeahead.module').then((m) => m.TypeaheadModule),
      },
      {
        path: AppRoutes.APP.WCOMPONENTS,
        loadChildren: () =>
          import('./modules/wcomponent/wcomponent.module').then((m) => m.WcomponentModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
