/* eslint-disable @typescript-eslint/no-namespace */
export namespace UnauthenticatedActionStateActions {
  export class SetPendingAction {
    public static readonly type = '[UnauthenticatedAction] Set Pending Action';

    constructor(
      public actionName: string,
      public actionData: unknown = null,
    ) {}
  }

  export class ClearPendingAction {
    public static readonly type = '[UnauthenticatedAction] Clear Pending Action';
  }
}
