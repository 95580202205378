import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
  IWorldChartData,
} from '@interfaces/filters/filters.interface';
import { FilterSectionName } from '@enums/filters/filters.enum';
import { ManageTmFiltersActions } from '../actions/manage-trademarks-filters.actions';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface ManageTmsFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
  trademarkIRNsList: string[];
}

@State<ManageTmsFiltersStateModel>({
  name: 'trademarksFilters',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    filtersSidebarCollapsedState: false,
    selectedFilters: {},
    trademarkIRNsList: [],
  },
})
@Injectable()
export class ManageTmsFiltersState extends BaseFiltersState<ManageTmsFiltersStateModel> {
  @Selector()
  public static getTrademarksIRNs(state: ManageTmsFiltersStateModel): string[] {
    return state.trademarkIRNsList;
  }

  @Selector()
  public static getSelectedFilters(state: ManageTmsFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  public static getWorldChartDesignationsData(state: ManageTmsFiltersStateModel): IWorldChartData {
    const designations: IFilterSection | undefined = state.filtersSections.find(
      (section: IFilterSection): boolean => {
        return section.sectionKey === FilterSectionName.Designations;
      },
    );

    if (designations) {
      return {
        options: designations.filters[0].options,
        selectedOptions: state.selectedFilters[FilterSectionName.Designations] || [],
      };
    }

    return {
      options: [],
      selectedOptions: [],
    };
  }

  @Selector()
  public static getWorldChartOfficeOfOriginData(
    state: ManageTmsFiltersStateModel,
  ): IWorldChartData {
    const designations: IFilterSection | undefined = state.filtersSections.find(
      (section: IFilterSection): boolean => {
        return section.sectionKey === FilterSectionName.OfficeOfOrigin;
      },
    );

    if (designations) {
      return {
        options: designations.filters[0].options,
        selectedOptions: state.selectedFilters[FilterSectionName.OfficeOfOrigin] || [],
      };
    }

    return {
      options: [],
      selectedOptions: [],
    };
  }

  @Selector()
  public static isCanDelegateStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanDelegate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isCanEditStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanEdit && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isDesignationsStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Designations && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isExpirationDateStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.ExpirationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isHolderNameStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.HolderName && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isOfficeOfOriginStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.OfficeOfOrigin && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isRegistrationDateStatisticsViewVisible(
    state: ManageTmsFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.RegistrationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isRepresentativeStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Representative && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isStatusStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Status && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static isAccessRightsStatisticsViewVisible(state: ManageTmsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.AccessRights && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }

    return false;
  }

  @Selector()
  public static getFiltersSidebarCollapsedState(state: ManageTmsFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Action(ManageTmFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  @Action(ManageTmFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  @Action(ManageTmFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  @Action(ManageTmFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }

  @Action(ManageTmFiltersActions.SetQuickFilterSelection)
  public override setQuickFilterSelection(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetQuickFilterSelection,
  ): void {
    super.setQuickFilterSelection(context, action);
  }

  @Action(ManageTmFiltersActions.SetTrademarksIRNs)
  public setTrademarksIRNs(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetTrademarksIRNs,
  ): void {
    context.setState(
      patch<ManageTmsFiltersStateModel>({
        trademarkIRNsList: action.trademarkIRNs,
      }),
    );
  }

  @Action(ManageTmFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<ManageTmsFiltersStateModel>,
    action: ManageTmFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }

  @Action(ManageTmFiltersActions.ResetSelectedFilters)
  public override resetSelectedFilters(context: StateContext<ManageTmsFiltersStateModel>): void {
    super.resetSelectedFilters(context);
  }

  @Action(ManageTmFiltersActions.ResetCollapsedFilters)
  public override resetCollapsedFilters(context: StateContext<ManageTmsFiltersStateModel>): void {
    super.resetCollapsedFilters(context);
  }
}
