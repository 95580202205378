import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoader {
  public loadScript(src: string, async = false): Observable<void> {
    const subject = new Subject<void>();
    document.head.appendChild(
      Object.assign(document.createElement('script'), {
        src: src,
        async: async,
        onload: () => {
          subject.next();
        },
        onerror: (error: any) => {
          console.error('load failure', src, error);
          subject.next();
        },
      }),
    );
    return subject.pipe(take(1));
  }
}
