import { StateContext } from '@ngxs/store';
import { IFilterSection } from '@interfaces/filters/filters.interface';
import { FindMonitMonitorAdvancedFiltersActions } from '@store/sidebar-filters/actions/findmonit-advancedsearch-filters.actions';
import { PaidPaymentsFiltersActions } from '@store/sidebar-filters/actions/paid-payments-filters.actions';
import { PaidPaymentsFiltersStateModel } from '@store/sidebar-filters/states/paid-payments-filters.state';
import { UnpaidPaymentsFiltersStateModel } from '@store/sidebar-filters/states/unpaid-payments-filters.state';
import { UnpaidPaymentsFiltersActions } from '@store/sidebar-filters/actions/unpaid-payments-filters.actions';
import { WatchlistFiltersStateModel } from '@store/sidebar-filters/states/watchlist-filters.state';
import { WatchlistFiltersActions } from '@store/sidebar-filters/actions/watchlist-filters.actions';
import { ManageTransactionsFiltersStateModel } from '@store/sidebar-filters/states/manage-transactions-filters.state';
import { ManageTransactionsFiltersActions } from '@store/sidebar-filters/actions/manage-transactions-filters.actions';
import { ManageTmsFiltersStateModel } from '@store/sidebar-filters/states/manage-trademarks-filters.state';
import { ManageTmFiltersActions } from '@store/sidebar-filters/actions/manage-trademarks-filters.actions';
import { FindMonitMonitorFiltersStateModel } from '@store/sidebar-filters/states/findmonit-monitor-filters.state';
import { FindMonitMonitorFiltersActions } from '@store/sidebar-filters/actions/findmonit-monitor-filters.actions';
import { FindMonitMonitorAdvancedFiltersStateModel } from '@store/sidebar-filters/states/findmonit-advancedsearch-filters.state';
import { FilterSectionName } from '@enums/filters/filters.enum';

type StatesModels =
  | FindMonitMonitorAdvancedFiltersStateModel
  | FindMonitMonitorFiltersStateModel
  | ManageTmsFiltersStateModel
  | ManageTransactionsFiltersStateModel
  | PaidPaymentsFiltersStateModel
  | UnpaidPaymentsFiltersStateModel
  | WatchlistFiltersStateModel;

type ActionsSetQuickFilterSelection =
  | FindMonitMonitorAdvancedFiltersActions.SetQuickFilterSelection
  | FindMonitMonitorFiltersActions.SetQuickFilterSelection
  | ManageTmFiltersActions.SetQuickFilterSelection
  | ManageTransactionsFiltersActions.SetQuickFilterSelection
  | PaidPaymentsFiltersActions.SetQuickFilterSelection
  | UnpaidPaymentsFiltersActions.SetQuickFilterSelection;

type ActionsSetFiltersSidebarCollapsed =
  | FindMonitMonitorAdvancedFiltersActions.SetFiltersSidebarCollapsed
  | FindMonitMonitorFiltersActions.SetFiltersSidebarCollapsed
  | ManageTmFiltersActions.SetFiltersSidebarCollapsed
  | ManageTransactionsFiltersActions.SetFiltersSidebarCollapsed
  | PaidPaymentsFiltersActions.SetFiltersSidebarCollapsed
  | UnpaidPaymentsFiltersActions.SetFiltersSidebarCollapsed
  | WatchlistFiltersActions.SetFiltersSidebarCollapsed;

type ActionSetSectionCollapsed =
  | FindMonitMonitorAdvancedFiltersActions.SetSectionCollapsed
  | FindMonitMonitorFiltersActions.SetSectionCollapsed
  | ManageTmFiltersActions.SetSectionCollapsed
  | ManageTransactionsFiltersActions.SetSectionCollapsed
  | PaidPaymentsFiltersActions.SetSectionCollapsed
  | UnpaidPaymentsFiltersActions.SetSectionCollapsed
  | WatchlistFiltersActions.SetSectionCollapsed;

type ActionsSetFiltersSections =
  | FindMonitMonitorAdvancedFiltersActions.SetFiltersSections
  | FindMonitMonitorFiltersActions.SetFiltersSections
  | ManageTmFiltersActions.SetFiltersSections
  | ManageTransactionsFiltersActions.SetFiltersSections
  | PaidPaymentsFiltersActions.SetFiltersSections
  | UnpaidPaymentsFiltersActions.SetFiltersSections
  | WatchlistFiltersActions.SetFiltersSections;

type ActionsRemoveTag =
  | FindMonitMonitorAdvancedFiltersActions.RemoveTag
  | FindMonitMonitorFiltersActions.RemoveTag
  | ManageTmFiltersActions.RemoveTag
  | ManageTransactionsFiltersActions.RemoveTag
  | PaidPaymentsFiltersActions.RemoveTag
  | UnpaidPaymentsFiltersActions.RemoveTag
  | WatchlistFiltersActions.RemoveTag;

type ActionsSetSelectedFilters =
  | FindMonitMonitorAdvancedFiltersActions.SetSelectedFilters
  | FindMonitMonitorFiltersActions.SetSelectedFilters
  | ManageTmFiltersActions.SetSelectedFilters
  | ManageTransactionsFiltersActions.SetSelectedFilters
  | PaidPaymentsFiltersActions.SetSelectedFilters
  | UnpaidPaymentsFiltersActions.SetSelectedFilters
  | WatchlistFiltersActions.SetSelectedFilters;

export class BaseFiltersState<T extends StatesModels> {
  /**
   * Remove tag from selected filters
   * @param context
   * @param action
   */
  public removeTag(context: StateContext<T>, action: ActionsRemoveTag): void {
    const state: T = context.getState();
    const { sectionKey, value } = action;

    if (state.selectedFilters[sectionKey] && Array.isArray(state.selectedFilters[sectionKey])) {
      const index: number = state.selectedFilters[sectionKey].indexOf(value);
      if (index !== -1) {
        const updatedSelectedFilters = {
          ...state.selectedFilters,
          [sectionKey]: [
            ...state.selectedFilters[sectionKey].slice(0, index),
            ...state.selectedFilters[sectionKey].slice(index + 1),
          ],
        };

        context.patchState({
          selectedFilters: updatedSelectedFilters,
        } as Partial<T>);
      }
    }
  }

  /**
   * Reset collapsed filters
   * @param context
   */
  public resetCollapsedFilters(context: StateContext<T>): void {
    const state: T = context.getState();

    context.patchState({
      ...state,
      filtersSectionsCollapsedState: {},
    });
  }

  /**
   * Reset selected filters
   * @param context
   */
  public resetSelectedFilters(context: StateContext<T>): void {
    const state: T = context.getState();

    context.patchState({
      ...state,
      selectedFilters: {},
    });
  }

  /**
   * Set filters sections
   * @param context
   * @param action
   */
  public setFiltersSections(context: StateContext<T>, action: ActionsSetFiltersSections): void {
    const { sectionData } = action;
    const state = context.getState();
    // Map over sectionData to update the collapsed states based on filtersSectionsCollapsedState
    const updatedSections = sectionData
      ? sectionData.map((section: IFilterSection) => {
          const isDefined = state.filtersSectionsCollapsedState[section.sectionKey] !== undefined;
          const isStatus = section.sectionKey === FilterSectionName.Status;
          let collapsed = true;

          // if status, set its collapsed state to false
          if (isStatus) {
            collapsed = false;
            state.filtersSectionsCollapsedState[section.sectionKey] = false;
          } else if (isDefined) {
            collapsed = state.filtersSectionsCollapsedState[section.sectionKey];
          }

          return {
            ...section,
            collapsed,
          };
        })
      : state.filtersSections;

    // Update the states with the new filtersSections
    const newState: T = {
      ...state,
      filtersSections: updatedSections,
    };

    context.setState(newState);
  }

  /**
   * Set filters sidebar collapsed state
   * @param context
   * @param action
   */
  public setFiltersSidebarCollapsed(
    context: StateContext<T>,
    action: ActionsSetFiltersSidebarCollapsed,
  ): void {
    context.patchState({
      filtersSidebarCollapsedState: action.isCollapsed,
    } as Partial<T>);
  }

  /**
   * Set quick filter selection
   * @param context
   * @param action
   */
  public setQuickFilterSelection(
    context: StateContext<T>,
    action: ActionsSetQuickFilterSelection,
  ): void {
    const state: T = context.getState();
    context.patchState({
      selectedFilters: {
        ...state.selectedFilters,
        ['quicksearch']: [action.quickSearchTerm],
      },
    } as unknown as Partial<T>);
  }

  /**
   * Set section collapsed state
   * @param context
   * @param action
   */
  public setSectionCollapsed(context: StateContext<T>, action: ActionSetSectionCollapsed): void {
    const { sectionKey, isCollapsed } = action;
    const state: T = context.getState();

    const updatedCollapsedState = {
      ...state.filtersSectionsCollapsedState,
      [sectionKey]: isCollapsed,
    };

    context.patchState({
      filtersSectionsCollapsedState: updatedCollapsedState,
    } as Partial<T>);
  }

  /**
   * Set selected filters
   * @param context
   * @param action
   */
  public setSelectedFilters(context: StateContext<T>, action: ActionsSetSelectedFilters): void {
    const { selectedFilters } = action;
    const state: T = context.getState();

    context.patchState({
      ...state,
      selectedFilters: { ...selectedFilters },
    });
  }
}
