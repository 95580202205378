import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { UnpaidPaymentsFiltersActions } from '@store/sidebar-filters/actions/unpaid-payments-filters.actions';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface UnpaidPaymentsFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
}

@State<UnpaidPaymentsFiltersStateModel>({
  name: 'unpaidFilters',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    filtersSidebarCollapsedState: false,
    selectedFilters: {},
  },
})
@Injectable()
export class UnpaidPaymentsFiltersState extends BaseFiltersState<UnpaidPaymentsFiltersStateModel> {
  @Selector()
  public static getSelectedFilters(state: UnpaidPaymentsFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  public static getFiltersSidebarCollapsedState(state: UnpaidPaymentsFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Action(UnpaidPaymentsFiltersActions.SetQuickFilterSelection)
  public override setQuickFilterSelection(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.SetQuickFilterSelection,
  ): void {
    super.setQuickFilterSelection(context, action);
  }

  @Action(UnpaidPaymentsFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  @Action(UnpaidPaymentsFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  @Action(UnpaidPaymentsFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  @Action(UnpaidPaymentsFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }

  @Action(UnpaidPaymentsFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<UnpaidPaymentsFiltersStateModel>,
    action: UnpaidPaymentsFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }
}
