import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { PaidPaymentsFiltersActions } from '@store/sidebar-filters/actions/paid-payments-filters.actions';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface PaidPaymentsFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
}

@State<PaidPaymentsFiltersStateModel>({
  name: 'paidFilters',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    filtersSidebarCollapsedState: false,
    selectedFilters: {},
  },
})
@Injectable()
export class PaidPaymentsFiltersState extends BaseFiltersState<PaidPaymentsFiltersStateModel> {
  @Selector()
  public static getFiltersSidebarCollapsedState(state: PaidPaymentsFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Selector()
  public static getSelectedFilters(state: PaidPaymentsFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Action(PaidPaymentsFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }

  @Action(PaidPaymentsFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  @Action(PaidPaymentsFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  @Action(PaidPaymentsFiltersActions.SetQuickFilterSelection)
  public override setQuickFilterSelection(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.SetQuickFilterSelection,
  ): void {
    super.setQuickFilterSelection(context, action);
  }

  @Action(PaidPaymentsFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  @Action(PaidPaymentsFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<PaidPaymentsFiltersStateModel>,
    action: PaidPaymentsFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }
}
