import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IAccessRight } from '@interfaces/access-rights/access-right.interface';
import { FindMonitMonitorFiltersState } from '@store/sidebar-filters/states/findmonit-monitor-filters.state';
import { FindMonitMonitorActions } from '@store/findmonit-monitor/actions/findmonit-monitor.action';

export interface FindMonitMonitorModel {
  count: number;
  data: string[];
  listView: boolean;
  searchKey: string;
  selectedColumns: number[];
  statisticsView: boolean;
  tmDetailsId: string;
  tmUserAccessRight: IAccessRight;
}

@State<FindMonitMonitorModel>({
  name: 'findmonitor',
  defaults: {
    count: 0,
    data: [],
    listView: true,
    searchKey: '',
    selectedColumns: [],
    statisticsView: false,
    tmDetailsId: '',
    tmUserAccessRight: { canEdit: false, canDelegate: false },
  },
  children: [FindMonitMonitorFiltersState],
})
@Injectable()
export class FindMonitMonitorState {
  @Selector()
  public static getSelectedColumns(state: FindMonitMonitorModel): number[] {
    return state.selectedColumns;
  }

  @Selector()
  public static getSelectedQuickSearch(state: FindMonitMonitorModel): string {
    return state.searchKey;
  }

  @Selector()
  public static getTmDetailsId(state: FindMonitMonitorModel): string {
    return state.tmDetailsId;
  }

  @Selector()
  public static getTmUserAccessRight(state: FindMonitMonitorModel): IAccessRight {
    return state.tmUserAccessRight;
  }

  @Selector()
  public static getListView(state: FindMonitMonitorModel): boolean {
    return state.listView;
  }

  @Selector()
  public static getStatisticsView(state: FindMonitMonitorModel): boolean {
    return state.statisticsView;
  }

  constructor() {}

  @Action(FindMonitMonitorActions.SetSelectedColumns)
  public setSelectedColumns(context: StateContext<FindMonitMonitorModel>, actionParams: any): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      selectedColumns: actionParams['selectedColumns'],
    });
  }

  @Action(FindMonitMonitorActions.SetSelectedQuickSearch)
  public setSearchKey(context: StateContext<FindMonitMonitorModel>, searchParam: string): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      searchKey: searchParam,
    });
  }

  @Action(FindMonitMonitorActions.SetTmDetailsId)
  public setTmDetailsId(context: StateContext<FindMonitMonitorModel>, actionParams: any): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      tmDetailsId: actionParams['id'],
    });
  }

  @Action(FindMonitMonitorActions.SetTmUserAccessRights)
  public setTmUserAccesRights(
    context: StateContext<FindMonitMonitorModel>,
    actionParams: any,
  ): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      tmUserAccessRight: actionParams['userAccessRight'],
    });
  }

  @Action(FindMonitMonitorActions.SetListView)
  public setListView(context: StateContext<FindMonitMonitorModel>): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      listView: true,
      statisticsView: false,
    });
  }

  @Action(FindMonitMonitorActions.SetStatisticsView)
  public setStatisticsView(context: StateContext<FindMonitMonitorModel>): void {
    const state: FindMonitMonitorModel = context.getState();
    context.setState({
      ...state,
      listView: false,
      statisticsView: true,
    });
  }
}
