/* eslint-disable @typescript-eslint/no-namespace */
import { IFilterSection, IFilterSelection } from '@interfaces/filters/filters.interface';

export namespace ManageTmFiltersActions {
  export class RemoveTag {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Remove Tag';

    constructor(
      public sectionKey: string,
      public value: string,
    ) {}
  }

  export class ResetCollapsedFilters {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Reset Selected Filters';
  }

  export class ResetSelectedFilters {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Reset Selected Filters';
  }

  export class SetFiltersSections {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set Filters Sections';

    constructor(public sectionData: IFilterSection[]) {}
  }

  export class SetFiltersSidebarCollapsed {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set Filters Sidebar Collapsed';

    constructor(public isCollapsed: boolean) {}
  }

  export class SetQuickFilterSelection {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set Quick Filter Selection';

    constructor(public quickSearchTerm: string) {}
  }

  export class SetSectionCollapsed {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set Filter Section Collapsed';

    constructor(
      public isCollapsed: boolean,
      public sectionKey: string,
    ) {}
  }

  export class SetSelectedFilters {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set Selected Filters';

    constructor(public selectedFilters: IFilterSelection) {}
  }

  export class SetTrademarksIRNs {
    public static readonly type: string = '[TRADEMARKS-FILTERS] Set list of trademarks IRNs';

    constructor(public trademarkIRNs: string[]) {}
  }
}
