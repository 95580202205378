import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ITrademarkSearchItems } from '@interfaces/global-search/global-search.interface';
import { IDataControl } from '@interfaces/common/data-control.interface';
import { SortOrderValue } from '@enums/common/data-control.enum';
import { WatchlistFiltersState } from '@store/sidebar-filters/states/watchlist-filters.state';
import { WatchlistActions } from '@store/findmonit-watchlist/actions/watchlist.actions';

export interface WatchlistStateModel {
  data: ITrademarkSearchItems[];
  count: number;
  selectedColumns: number[];
  dataControl: IDataControl;
  tmDetailsId: '';
}

@State<WatchlistStateModel>({
  name: 'watchlist',
  defaults: {
    data: [],
    count: 0,
    selectedColumns: [],
    dataControl: {
      sortOrder: SortOrderValue.DESC,
      sortKey: 'default',
      pageTotal: 10,
      currentPage: 0,
      pageLimit: 10,
    },
    tmDetailsId: '',
  },
  children: [WatchlistFiltersState],
})
@Injectable()
export class WatchlistState {
  @Selector()
  public static getData(state: WatchlistStateModel): ITrademarkSearchItems[] {
    return state.data;
  }

  @Selector()
  public static getCount(state: WatchlistStateModel): number {
    return state.count;
  }

  @Selector()
  public static getSelectedColumns(state: WatchlistStateModel): number[] {
    return state.selectedColumns;
  }

  @Selector()
  public static getDataControl(state: WatchlistStateModel): IDataControl {
    return state.dataControl;
  }

  @Action(WatchlistActions.SetData)
  public setData(ctx: StateContext<WatchlistStateModel>, action: WatchlistActions.SetData): void {
    ctx.patchState({
      data: action.data,
    });
  }

  @Action(WatchlistActions.SetSelectedColumns)
  public setSelectedColumns(
    ctx: StateContext<WatchlistStateModel>,
    action: WatchlistActions.SetSelectedColumns,
  ): void {
    ctx.patchState({
      selectedColumns: action.selectedColumns,
    });
  }

  @Action(WatchlistActions.SetDataControl)
  public setDataControl(ctx: StateContext<WatchlistStateModel>, action: any) {
    const selectedDataControl = action.dataControl;
    const state = ctx.getState();
    ctx.setState({
      ...state,
      dataControl: { ...selectedDataControl },
    });
  }

  @Action(WatchlistActions.SetTmDetailsId)
  public setTmDetailsId(context: StateContext<WatchlistStateModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      tmDetailsId: actionParams['id'],
    });
  }
}
