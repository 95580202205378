import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { ManageTransactionsFiltersActions } from '@store/sidebar-filters/actions/manage-transactions-filters.actions';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface ManageTransactionsFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
}

@State<ManageTransactionsFiltersStateModel>({
  name: 'transactionsFilters',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    filtersSidebarCollapsedState: false,
    selectedFilters: {},
  },
})
@Injectable()
export class ManageTransactionsFiltersState extends BaseFiltersState<ManageTransactionsFiltersStateModel> {
  @Selector()
  public static getFiltersSidebarCollapsedState(
    state: ManageTransactionsFiltersStateModel,
  ): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Selector()
  public static getSelectedFilters(state: ManageTransactionsFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Action(ManageTransactionsFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }

  @Action(ManageTransactionsFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  @Action(ManageTransactionsFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  @Action(ManageTransactionsFiltersActions.SetQuickFilterSelection)
  public override setQuickFilterSelection(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetQuickFilterSelection,
  ): void {
    super.setQuickFilterSelection(context, action);
  }

  @Action(ManageTransactionsFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  @Action(ManageTransactionsFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }

  @Action(ManageTransactionsFiltersActions.ResetSelectedFilters)
  public override resetSelectedFilters(
    context: StateContext<ManageTransactionsFiltersStateModel>,
  ): void {
    super.resetSelectedFilters(context);
  }
}
