import { IDynamicFormRowData } from '@interfaces/certified-documents/dynamic-form.interface';

export namespace CdDynamicFormActions {
  export class UpdateFormData {
    static readonly type = '[CD-DYNAMIC-FORM] Update Data';
    constructor(
      public id: string,
      public updatedFormData: IDynamicFormRowData,
    ) {}
  }

  export class AddFormData {
    static readonly type = '[CD-DYNAMIC-FORM] Add Data';
    constructor(public formRowData: IDynamicFormRowData) {}
  }

  export class DeleteFormData {
    static readonly type = '[CD-DYNAMIC-FORM] Delete Data';
    constructor(public rowIndex: number) {} // Pass the index of the row to delete
  }

  export class ResetFormData {
    static readonly type = '[CD-DYNAMIC-FORM] Reset Data';
  }
}
