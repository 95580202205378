import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthStateActions } from '@store/auth-state/actions/auth-state.action';
import {
  AuthConfig,
  OAuthErrorEvent,
  OAuthEvent,
  OAuthService,
  OAuthSuccessEvent,
  UserInfo,
} from 'angular-oauth2-oidc';
import { StateClear } from 'ngxs-reset-plugin';
import { environment } from 'src/environments/environment';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Injectable({
  providedIn: 'root',
})
export class WipoAuthService {
  private authConfig: AuthConfig = {
    issuer: environment.oidcIssuer,
    clientId: environment.oidcClientId,
    redirectUri: environment.oidcRedirectUri,
    responseType: environment.oidcResponseType,
    oidc: true,
    scope: environment.oidcScope,
  };

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private store: Store,
    private toastMessageService: ToastMessageService,
  ) {}

  // entry point
  initialize(): Promise<void> {
    return new Promise(async (resolve) => {
      this.oauthService.configure(this.authConfig);
      this.watchSilentRefreshAndTokenExpiration();
      this.oauthService.setupAutomaticSilentRefresh();

      await this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .catch((error) => console.error('ERROR: ' + error));

      if (this.getUserInfo()) {
        await this.setAuthenticatedMode();
      }

      // Inside your component or service after successful login
      const returnUrl = localStorage.getItem('returnUrl'); // Get the stored returnUrl
      if (returnUrl) {
        // Navigate the user back to the original route
        this.router.navigateByUrl(returnUrl);
        // Clear the stored returnUrl
        localStorage.removeItem('returnUrl');
      }

      resolve();
    });
  }

  private watchSilentRefreshAndTokenExpiration(): void {
    this.oauthService.events.subscribe({
      next: (event: OAuthEvent) => {
        if (event instanceof OAuthErrorEvent) {
          //  console.error('wipoAuthService: OAuthErrorEvent:', event);
          if (event.type === 'silent_refresh_error') {
            this.loginWithUserPassword();
          }
        } else if (event instanceof OAuthSuccessEvent && event.type === 'silently_refreshed') {
          console.debug(
            'wipoAuthService: OAuthEvent Access token REFRESHED, valid until:',
            new Date(this.oauthService.getAccessTokenExpiration()),
          );
        } else if (event.type === 'token_expires') {
          const successEvent = event as OAuthSuccessEvent;
          if (successEvent.info === 'access_token') {
            this.store.dispatch(new StateClear());
            this.logOut();
          }
        }
      },
      error: (error: unknown) => {
        // TODO: (i18n) Find a fix for circular dependency when injecting the translateService
        this.toastMessageService.showError('Error', 'Failed to authenticate. Please log in again.');
        console.error('Failed to authenticate. Please log in again:', error);
      },
    });
  }

  loginWithUserPassword(): void {
    const returnUrl = this.router.url;
    localStorage.setItem('returnUrl', returnUrl);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.initCodeFlow();
  }

  logOut() {
    const returnUrl = this.router.url;
    localStorage.setItem('returnUrl', returnUrl);
    this.oauthService.revokeTokenAndLogout();
    this.store.dispatch(new AuthStateActions.SetUserLoggedIn(false));
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  getUserInfo(): UserInfo {
    const userInfo = this.oauthService.getIdentityClaims();
    console.log('wipoAuthService: get user info', JSON.stringify(userInfo, null, 2));
    return this.oauthService.getIdentityClaims() as UserInfo;
  }

  public accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  private async setAuthenticatedMode(): Promise<void> {
    console.debug('wipoAuthService: set authenticated mode');
    this.store.dispatch(new AuthStateActions.SetUserLoggedIn(true));
  }
}
