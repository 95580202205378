import { Component, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LanguageService } from '@services/language/language.service';
import { IMenuItem } from './shared/interfaces/ui/menu-item.interface';
import { StateClear } from 'ngxs-reset-plugin';
import { Select, Store } from '@ngxs/store';
import { UserInfo } from 'angular-oauth2-oidc';
import { WipoAuthService } from '@services/auth/wipo-auth.service';
import { AppRoutes } from '@constants/app-routes.constants';
import { combineLatest, map, Observable } from 'rxjs';
import { AuthState } from '@store/auth-state/states/auth-state.state';
import { AuthStateActions } from '@store/auth-state/actions/auth-state.action';
import { CustomerSupportService } from '@services/customer-support/customer-support.service';

@Component({
  selector: 'emad-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CustomerSupportService],
})
export class AppComponent implements OnInit {
  @Select(AuthState.isUserLoggedIn) public isUserLoggedIn$!: Observable<boolean>;
  @Select(AuthState.getCustomerSupportEmail) public customerSupportEmail$!: Observable<string>;

  public routes: IMenuItem[] = [
    { route: AppRoutes.APP.EMADRID, menuName: 'MENU.EMADRID' },
    { route: AppRoutes.APP.FILEAPP, menuName: 'MENU.FILE_AN_APPLICATION' },
    { route: AppRoutes.APP.MANAGE, menuName: 'MENU.MANAGE_YOUR_TRADEMARKS' },
    { route: AppRoutes.APP.FINDMONIT, menuName: 'MENU.FIND_AND_MONITOR' },
  ];

  private renderer: Renderer2 | undefined;
  public userProfile: UserInfo | undefined;
  public customerSupportEmail: string | null = null;

  constructor(
    public languageService: LanguageService,
    private wipoAuthService: WipoAuthService,
    private rendererFactory: RendererFactory2,
    private store: Store,
    private customerSupportService: CustomerSupportService,
  ) {}

  public get mustShowCustomerSupportBar$(): Observable<boolean> {
    return combineLatest([this.isUserLoggedIn$, this.customerSupportEmail$]).pipe(
      map(([isUserLoggedIn, customerSupportEmail]) => {
        this.customerSupportEmail = customerSupportEmail;

        // Remove customer support email when user logs out to avoid displaying stale data
        if (!this.isUserLoggedIn$) {
          this.onEndSession();
        }

        return isUserLoggedIn && !!customerSupportEmail;
      }),
    );
  }

  public ngOnInit(): void {
    this.store.dispatch(
      new AuthStateActions.SetCustomerSupportEmail(
        this.customerSupportService.getCustomerSupportEmail(),
      ),
    );

    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.renderer.listen(document, 'wipoLoginClick', () => {
      this.wipoAuthService.loginWithUserPassword();
    });

    this.renderer.listen(document, 'wipoLogoutClick', () => {
      this.logOut();
    });

    this.renderer.listen(
      document,
      'wipoAuthenticatedStatus',
      (customEvent: CustomEvent<{ value: boolean }>) => {
        if (customEvent.detail.value) {
          this.store.dispatch(new AuthStateActions.SetUserLoggedIn(true));
        } else {
          this.logOut();
        }
      },
    );

    this.languageService.initialLanguageSetup();

    // Event generated by WIPO-navbar when language get changed
    this.renderer.listen(
      document,
      'wipoLanguageChange',
      (customEvent: CustomEvent<{ languageSelected: string }>) => {
        if (this.languageService.isLanguageSupported(customEvent.detail.languageSelected)) {
          this.languageService.currentLanguage = customEvent.detail.languageSelected;
          this.languageService.changeLanguage();
        }
      },
    );

    this.isUserLoggedIn$.subscribe({
      next: (userAuthenticated: boolean) => {
        if (userAuthenticated) {
          this.userProfile = this.wipoAuthService.getUserInfo();
          console.log('user.email', this.userProfile);
          if (this.userProfile && this.userProfile.email) {
            this.store.dispatch(new AuthStateActions.SetUserEmail(this.userProfile.email));
          }
        } else {
          this.userProfile = undefined; // Reset userProfile when not authenticated
        }
      },
      error: (error: unknown) => {
        console.error('NGXS: Error selecting user status:', error);
      },
    });

    // TODO restore after demo
    // if (!environment.production) {
    //   this.routes.push({ route: AppRoutes.APP.POC, menuName: 'POC' });
    //   this.routes.push({ route: AppRoutes.APP.WCOMPONENTS, menuName: 'WComponents' });
    // }
  }

  public login(): void {
    this.wipoAuthService.loginWithUserPassword();
  }

  public logOut(): void {
    this.store.dispatch(new StateClear());
    this.wipoAuthService.logOut();
  }

  public onEndSession(): void {
    this.store.dispatch(new AuthStateActions.RemoveCustomerSupportEmail());
    this.customerSupportService.endSession();
  }
}
