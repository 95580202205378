import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UnauthenticatedActionStateActions } from '../actions/unauthenticated.action';

export interface UnauthenticatedActionStateModel {
  pendingAction: string | null;
  actionData: unknown | null;
}

@State<UnauthenticatedActionStateModel>({
  name: 'unauthenticatedState',
  defaults: {
    pendingAction: null,
    actionData: null,
  },
})
@Injectable()
export class UnauthenticatedState {
  @Selector()
  public static getPendingAction(state: UnauthenticatedActionStateModel): string | null {
    return state.pendingAction;
  }

  @Selector()
  public static getActionData(state: UnauthenticatedActionStateModel): unknown | null {
    return state.actionData;
  }

  @Action(UnauthenticatedActionStateActions.SetPendingAction)
  public setPendingAction(
    ctx: StateContext<UnauthenticatedActionStateModel>,
    action: UnauthenticatedActionStateActions.SetPendingAction,
  ): void {
    ctx.setState({
      pendingAction: action.actionName,
      actionData: action.actionData,
    });
  }

  @Action(UnauthenticatedActionStateActions.ClearPendingAction)
  public clearPendingAction(ctx: StateContext<UnauthenticatedActionStateModel>): void {
    ctx.setState({
      pendingAction: null,
      actionData: null,
    });
  }
}
