import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WipoAuthService } from '../auth/wipo-auth.service';
import { EmadHttpHeaders } from '@enums/http-headers.enum';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  baseUrl = environment.backofficeUrl;

  constructor(
    private authService: WipoAuthService,
    private browserStorageService: BrowserStorageService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if the request URL matches the specific URL where you want to add the token
    if (req.url.includes(this.baseUrl)) {
      const idToken = this.authService.getIdToken();
      const accessToken = this.authService.accessToken();

      if (idToken !== null && accessToken !== null) {
        // Clone the request and add the authorization header
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            'ID-Token': idToken,
            'X-EMAIL':
              this.browserStorageService.getFromSessionStorage<string>(EmadHttpHeaders.X_EMAIL) ||
              '',
          },
        });
        return next.handle(authReq);
      }
      // Pass the modified request to the next handler
      return next.handle(req);
    }

    // If the URL doesn't match, proceed without modifying the request
    return next.handle(req);
  }
}
