import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { dataControlConfigEnum } from 'src/app/shared/constants/data-control-constants';
import { ManageTransactionsFiltersState } from '@store/sidebar-filters/states/manage-transactions-filters.state';
import { ExpandedRow } from '@interfaces/transactions/transaction.interface';
import { ManageTransactionsActions } from '@store/manage-transactions/actions/manage-transactions.actions';

export interface ManageTransactionsModel {
  data: string[];
  count: number;
  expandedRows: ExpandedRow;
  selectedColumns: number[];
  currentPage: number;
  sortKey: string;
  sortOrder: string;
}

interface SetCurrentPageParams {
  currentPage: number;
}

interface SetExpandedRowsParams {
  expandedRows: ExpandedRow;
}

interface SetSortParams {
  sortKey: string;
  sortOrder: string;
}

interface SetSelectedColumnsParams {
  selectedColumns: number[];
}

@State<ManageTransactionsModel>({
  name: 'manageTransactions',
  defaults: {
    data: [],
    count: 0,
    expandedRows: {},
    selectedColumns: [],
    currentPage: 1,
    sortKey: dataControlConfigEnum.WIPO_REF_SORT_KEY,
    sortOrder: dataControlConfigEnum.SORT_ORDER_ASC,
  },
  children: [ManageTransactionsFiltersState],
})
@Injectable()
export class ManageTransactionsState {
  @Selector()
  public static getCurrentPage(state: ManageTransactionsModel): number {
    return state.currentPage;
  }

  @Selector()
  public static getSortKey(state: ManageTransactionsModel): string {
    return state.sortKey;
  }

  @Selector()
  public static getSortOrder(state: ManageTransactionsModel): string {
    return state.sortOrder;
  }

  @Selector()
  public static getExpandedRows(state: ManageTransactionsModel): ExpandedRow {
    return state.expandedRows;
  }

  @Selector()
  public static getSelectedColumns(state: ManageTransactionsModel): number[] {
    return state.selectedColumns;
  }

  @Action(ManageTransactionsActions.SetCurrentPage)
  public setCurrentPage(
    context: StateContext<ManageTransactionsModel>,
    actionParams: SetCurrentPageParams,
  ): void {
    const state = context.getState();
    context.setState({
      ...state,
      currentPage: actionParams['currentPage'],
    });
  }

  @Action(ManageTransactionsActions.SetSort)
  public setSort(
    context: StateContext<ManageTransactionsModel>,
    actionParams: SetSortParams,
  ): void {
    const state = context.getState();
    context.setState({
      ...state,
      sortKey: actionParams['sortKey'],
      sortOrder: actionParams['sortOrder'],
    });
  }

  @Action(ManageTransactionsActions.SetExpandedRows)
  public setExpandedRows(
    context: StateContext<ManageTransactionsModel>,
    actionParams: SetExpandedRowsParams,
  ): void {
    const state = context.getState();
    context.setState({
      ...state,
      expandedRows: actionParams['expandedRows'],
    });
  }

  @Action(ManageTransactionsActions.SetSelectedColumns)
  public setSelectedColumns(
    context: StateContext<ManageTransactionsModel>,
    actionParams: SetSelectedColumnsParams,
  ): void {
    const state = context.getState();
    context.setState({
      ...state,
      selectedColumns: actionParams['selectedColumns'],
    });
  }

  @Action(ManageTransactionsActions.ResetExpandedRows)
  public resetExpandedRows(context: StateContext<ManageTransactionsModel>): void {
    const state = context.getState();
    context.setState({
      ...state,
      expandedRows: {},
    });
  }

  @Action(ManageTransactionsActions.ResetSelectedColumns)
  public resetSelectedColumns(context: StateContext<ManageTransactionsModel>): void {
    const state = context.getState();
    context.setState({
      ...state,
      selectedColumns: [],
    });
  }
}
