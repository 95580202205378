import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IEMadridUserService } from './i-emadrid-user.interface';
import { IEMadridUser } from '@interfaces/common/emadrid-user.interface';

@Injectable({
  providedIn: 'root',
})
export class EMadridUserService implements IEMadridUserService {
  private apiUrl = 'https://wipo.api.com/users'; // To be replaced/env var

  constructor(private http: HttpClient) {}

  findEMadridUserById(id: string): Observable<IEMadridUser | undefined> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<IEMadridUser>(url);
  }

  findEMadridUserByEmail(email: string): Observable<IEMadridUser | undefined> {
    const params = { email };
    return this.http.get<IEMadridUser>(this.apiUrl, { params });
  }

  findEMadridUserByName(name: string): Observable<IEMadridUser | undefined> {
    const params = { name };
    return this.http.get<IEMadridUser>(this.apiUrl, { params });
  }
}
