/**
 * it finds circular references and replace them with [Circular]
 *
 * This is needed in order to avoid an exception to happen when a JSON.stringify method is called
 * for more details on this, please read the Mozilla docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
 * @returns the object with no circular reference
 */
export function getCircularReplacer() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ancestors: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (_: any, value: unknown) {
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // `this` is the object that value is contained in,
    // i.e., its direct parent.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    while (ancestors.length > 0 && ancestors.at(-1) !== this) {
      ancestors.pop();
    }
    if (ancestors.includes(value)) {
      return '[Circular]';
    }
    ancestors.push(value);
    return value;
  };
}
