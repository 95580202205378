/* eslint-disable @typescript-eslint/no-namespace */
import { IFilterSection, IFilterSelection } from '@interfaces/filters/filters.interface';

export namespace WatchlistFiltersActions {
  export class RemoveTag {
    public static readonly type: string = '[WATCHLIST] Remove Tag';

    constructor(
      public sectionKey: string,
      public value: string,
    ) {}
  }

  export class SetFiltersSections {
    public static readonly type: string = '[WATCHLIST] Set Filters Sections';

    constructor(public sectionData: IFilterSection[]) {}
  }

  export class SetFiltersSidebarCollapsed {
    public static readonly type: string = '[WATCHLIST] Set Filters Sidebar Collapsed';

    constructor(public isCollapsed: boolean) {}
  }

  export class SetSectionCollapsed {
    public static readonly type: string = '[WATCHLIST] Set Filter Section Collapsed';

    constructor(
      public isCollapsed: boolean,
      public sectionKey: string,
    ) {}
  }

  export class SetSelectedFilters {
    public static readonly type: string = '[WATCHLIST] Set Selected Filters';

    constructor(public selectedFilters: IFilterSelection) {}
  }

  export class SetTrademarksIrns {
    static readonly type = '[FIND-MONITOR-AS] Set list of trademarks Irns';
    constructor(public trademarkIrns: string[]) {}
  }
}
