import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CdDynamicFormActions } from '../actions/cd-dynamic-form.actions';
import { IDynamicFormRowData } from '@interfaces/certified-documents/dynamic-form.interface';

export interface CdDynamicFormModel {
  formData: IDynamicFormRowData[];
}

@State<CdDynamicFormModel>({
  name: 'cdDynamicForm',
  defaults: {
    formData: [],
  },
})
@Injectable()
export class CdDynamicFormState {
  @Selector()
  static getFormData(state: CdDynamicFormModel) {
    return state.formData;
  }

  /**
   * Selector used to identify if the address for the certified documents dynamic form should be required or not
   *
   * @param state
   * @returns
   */
  @Selector()
  static shouldAddressBeRequired(state: CdDynamicFormModel): boolean {
    let shouldAddressBeRequired = false;
    for (const formRow of state.formData) {
      if (formRow.paperCopy === 'Yes') {
        shouldAddressBeRequired = true;
      }
    }
    return shouldAddressBeRequired;
  }

  @Action(CdDynamicFormActions.AddFormData)
  addFormRow(ctx: StateContext<CdDynamicFormModel>, action: CdDynamicFormActions.AddFormData) {
    const state = ctx.getState();
    const newFormData = [...state.formData, action.formRowData];
    ctx.patchState({
      formData: newFormData,
    });
  }

  @Action(CdDynamicFormActions.UpdateFormData)
  updateFormData(
    ctx: StateContext<CdDynamicFormModel>,
    action: CdDynamicFormActions.UpdateFormData,
  ) {
    const state = ctx.getState();
    const updatedFormData = state.formData.map((row) => {
      if (row.id === action.id) {
        return action.updatedFormData;
      }
      return row;
    });

    ctx.setState({
      ...state,
      formData: updatedFormData,
    });
  }

  @Action(CdDynamicFormActions.DeleteFormData)
  deleteFormData(
    ctx: StateContext<CdDynamicFormModel>,
    action: CdDynamicFormActions.DeleteFormData,
  ) {
    const state = ctx.getState();
    const filteredFormData = state.formData.filter((_, index) => index !== action.rowIndex);
    ctx.setState({
      ...state,
      formData: filteredFormData,
    });
  }

  @Action(CdDynamicFormActions.ResetFormData)
  resetFormData(ctx: StateContext<CdDynamicFormModel>) {
    ctx.setState({
      formData: [],
    });
  }
}
