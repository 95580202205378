import { Injectable } from '@angular/core';
import { getCircularReplacer } from '@functions/get-circular-replacer';

@Injectable({ providedIn: 'root' })
export class BrowserStorageService {
  public setIntoSessionStorage<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value, getCircularReplacer()));
  }

  public getFromSessionStorage<T>(key: string): T {
    return sessionStorage.getItem(key) as T;
  }

  public removeFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }
}
