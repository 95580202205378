import { IAccessRight } from '@interfaces/access-rights/access-right.interface';

export namespace FindMonitMonitorActions {
  export class SetSelectedColumns {
    static readonly type = '[FINDMONIT] Set selected columns';
    constructor(public selectedColumns: Array<number>) {}
  }

  export class SetSelectedQuickSearch {
    static readonly type = '[FINDMONIT] Set quick search key';
    constructor(public data: string) {}
  }

  export class SetTmDetailsId {
    static readonly type = '[FINDMONIT] Set Trademark Details ID';
    constructor(public id: string) {}
  }

  export class SetTmUserAccessRights {
    static readonly type = '[FINDMONIT] Set Trademark AccessRights';
    constructor(public userAccessRight: IAccessRight) {}
  }

  export class SetListView {
    static readonly type = '[FINDMONIT] Set List View';
    constructor() {}
  }

  export class SetStatisticsView {
    static readonly type = '[FINDMONIT] Set Statistics View';
    constructor() {}
  }

  export class SetTrademarksIds {
    static readonly type = '[FINDMONIT] Set list of trademarks Ids';
    constructor(public trademarkIds: string[]) {}
  }
}
