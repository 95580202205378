import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[emadActiveLink]',
  standalone: true,
})
export class ActiveLinkDirective implements OnInit, OnDestroy {
  @Input('emadActiveLink') linkRoute!: string;
  @Input() urlPartIndex: number = 0;

  private routerSubscription!: Subscription;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.checkActiveLink();

    this.routerSubscription = this.router.events.subscribe({
      next: (event: any) => {
        if (event instanceof NavigationEnd) {
          this.checkActiveLink();
        }
      },
      error: (error: any) => {
        console.error('Error in emadActiveLink directive:', error);
      },
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private checkActiveLink(): void {
    const currentUrlParts = this.router.url.split('/').filter((part) => part !== '');
    const routeParts = this.linkRoute.split('/').filter((part) => part !== '');

    if (currentUrlParts[this.urlPartIndex] === routeParts[0]) {
      this.renderer.addClass(this.elementRef.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'active');
    }
  }
}
