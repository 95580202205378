/* eslint-disable @typescript-eslint/no-namespace */
export namespace AuthStateActions {
  export class SetUserLoggedIn {
    public static readonly type = '[Auth] Set User Logged In';

    constructor(public isLoggedIn: boolean) {}
  }

  export class SetUserEmail {
    public static readonly type = '[Auth] Set User Email';

    constructor(public userEmail: string) {}
  }

  export class SetCustomerSupportEmail {
    public static readonly type = '[Auth] Set Customer Support Email';

    constructor(public customerEmail: string | null) {}
  }

  export class RemoveCustomerSupportEmail {
    public static readonly type = '[Auth] Remove Customer Support Email';
  }
}
