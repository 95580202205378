/* eslint-disable @typescript-eslint/no-namespace */
import { ITransactionTableColumn } from '@interfaces/transactions/transaction.interface';

export namespace ManageTransactionsActions {
  export class SetSelectedColumns {
    public static readonly type = '[TRANSACTION] Set selected columns';

    constructor(public selectedColumns: Array<ITransactionTableColumn>) {}
  }

  export class SetExpandedRows {
    public static readonly type = '[TRANSACTION] Set expanded rows';

    constructor(public expandedRows: object) {}
  }

  export class ResetExpandedRows {
    public static readonly type = '[TRANSACTION] reset expanded rows';
  }

  export class ResetSelectedColumns {
    public static readonly type = '[TRANSACTION] reset selected columns';
  }

  export class SetCurrentPage {
    public static readonly type = '[TRANSACTION] Set current page';

    constructor(public currentPage: number) {}
  }

  export class SetSort {
    public static readonly type = '[TRANSACTION] Set sortOrder & sort Key';

    constructor(
      public sortKey: string,
      public sortOrder: string,
    ) {}
  }
}
