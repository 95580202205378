export enum FilterSectionName {
  CanDelegate = 'canDelegate',
  CanEdit = 'canEdit',
  Designations = 'designations',
  ExpirationDate = 'expirationDate',
  HolderName = 'holder',
  OfficeOfOrigin = 'officeOfOrigin',
  RegistrationDate = 'registrationDate',
  Representative = 'representative',
  Status = 'status',
  AccessRights = 'accessRights',
}
