<p-toast
  position="bottom-right"
  [life]="5000" />

<ng-container *ngIf="(mustShowCustomerSupportBar$ | async) === true">
  <emad-customer-support-bar
    [customerSupportEmail]="customerSupportEmail"
    (endSessionEvent)="onEndSession()"></emad-customer-support-bar>
</ng-container>

<wipo-navbar
  applicationName="eMadrid"
  appId="app-0374"
  language="{{ languageService.currentLanguage }}"
  login="EVENT"
  logout="EVENT"
  languageOptions='[{"code":"en"},{"code":"fr"},{"code":"es"}]'
  applicationCategory="marks">
  <div style="background: black; height: 48px; width: 100%"></div>
</wipo-navbar>

<w-sticky-bar
  [sticky]="false"
  landingClass="Consectetur dolor"
  style="display: flex">
  <w-sticky-bar-link
    *ngFor="let item of routes; let i = index"
    [routerLink]="item.route"
    emadActiveLink="{{ '/' + item.route }}"
    label="{{ item.menuName | translate }}" />
  <w-input-search
    placeholder="enter search"
    info="Dolor dolore"
    error="Fugiat pariatur" />
</w-sticky-bar>

<div class="page-wrapper">
  <router-outlet />
</div>
