import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ManagePortfoliosActions } from '../actions/manage-portfolios.action';

export interface ManagePortfoliosModel {
  id: number;
  name: string;
  description: string;
}

@State<ManagePortfoliosModel>({
  name: 'managePortfolios',
  defaults: {
    id: 0,
    name: '',
    description: '',
  },
})
@Injectable()
export class ManagePortfoliosState {
  @Selector()
  static getState(state: ManagePortfoliosModel) {
    return state;
  }
  constructor() {}

  @Action(ManagePortfoliosActions.SetSelectedPortfolio)
  setSelectedPortfolio(context: StateContext<ManagePortfoliosModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      ...actionParams.data,
    });

    console.log(actionParams);
    console.log(context);
  }
}
