export const dataControlConfigEnum = {
  ACCESS_RIGHTS_SORT_KEY: 'accessRights',
  AUDIT_LOG_SORT_KEY: 'timestamp',
  DATE: 'date',
  DESIGNATED_COUTRACTING_PARTY: 'designatedContractingParty',
  DESIGNATION_SORT_KEY: 'designation',
  EMAIL_SORT_KEY: 'email',
  EXPIRATION_DATE_SORT_KEY: 'expirationDate',
  HOLDER_ADDRESS_SORT_KEY: 'holderAddress',
  HOLDER_COUNTRY_SORT_KEY: 'holderCountry',
  IRN_SORT_KEY: 'irn',
  MAXIMUM_BULK_SELECTION: 500,
  MAXIMUM_BULK_SELECTION_FOR_TOTAL_CHANGE_IN_OWNERSHIP: 100,
  NAME_SORT_KEY: 'name',
  ORIGIN_SORT_KEY: 'office',
  PROTECTION_STATUS: 'protectionStatus',
  REGISTRATION_DATE: 'registrationDate',
  REPRESENTATIVE_SORT_KEY: 'representative',
  SORT_ORDER_ASC: 'asc' as const,
  SORT_ORDER_DESC: 'desc' as const,
  TEAM_SORT_KEY: 'team',
  TRADEMARK_SORT_KEY: 'trademarkName',
  VIENNA_SORT_KEY: 'classification',
  WIPO_REF_SORT_KEY: 'wipoRef',
  PORTFOLIO_NAME: 'portfolioName',
};
