import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmadHttpHeaders } from '@enums/http-headers.enum';
import { IErrorsResponse } from '@interfaces/common/error-response.interface';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { Observable } from 'rxjs';

export interface IViewAsCustomerResponse {
  errors: IErrorsResponse;
  exists: boolean;
}

export interface CustomerRequestMetadata {
  email: string;
}

@Injectable()
export class CustomerSupportService {
  constructor(
    private httpClient: HttpClient,
    private browserStorageService: BrowserStorageService,
  ) {}

  public viewAsCustomer(
    customerRequestMetadata: CustomerRequestMetadata,
  ): Observable<IViewAsCustomerResponse> {
    return this.httpClient.post<IViewAsCustomerResponse>(
      `/v1/customer_support`,
      customerRequestMetadata,
    );
  }

  public endSession(): void {
    this.browserStorageService.removeFromSessionStorage(EmadHttpHeaders.X_EMAIL);
  }

  public getCustomerSupportEmail(): string | null {
    return this.browserStorageService.getFromSessionStorage<string>(EmadHttpHeaders.X_EMAIL);
  }
}
