export const AppRoutes = {
  APP: {
    EMADRID: 'e-madrid',
    FILEAPP: 'fileapp',
    FINDMONIT: 'findmonit',
    MANAGE: 'manage',
    POC: 'poc',
    WCOMPONENTS: 'wcomponents',
  },
  FINDMONIT: {
    ADVANCED_SEARCH: 'advanced-search',
    QUICK_SEARCH: 'quick-search',
    GAZETTE: 'gazette',
    WATCHLIST: 'watchlist',
    REPORTS: 'reports',
  },
  TRANSACTION: {
    DETAILS: 'details/:id',
  },
  TRADEMARKS: {
    DETAILS: 'details/:trademarkIrn',
    SUMMARY: 'summary',
    DESIGNATION: 'designation',
    TRANSACTIONS: 'transactions',
    CERTIFIED_DOCUMENTS: 'certified-documents',
    PAYMENTS: 'payments',
    MANAGE_ACCESS: 'manage-access',
    AUDIT_LOG: 'audit-log',
  },
  PAYMENTS: {
    PAID: 'paid',
    UNPAID: 'unpaid',
  },
  MANAGE_TRADEMARKS: {
    TRADEMARKS: 'trademarks',
    TRANSACTIONS: 'transactions',
    PAYMENTS: 'payments',
    INSIGHTS: 'insights',
    PORTFOLIOS: 'portfolios',
    LOG: 'log',
  },
  GAZETTE: {
    DETAILS: 'details/:trademarkIrn',
  },
  PORTFOLIOS: {
    DETAILS: 'details/:id',
    TRADEMARKS: 'trademarks',
    TRANSACTIONS: 'transactions',
    PAYMENTS: 'payments',
    INSIGHTS: 'insights',
  },
  QUICKSEARCH: {
    DETAILS: 'details/:trademarkIrn',
    SUMMARY: 'summary',
    DESIGNATION: 'designation',
    TRANSACTIONS: 'transactions',
    CERTIFIED_DOCUMENTS: 'certified-documents',
    PAYMENTS: 'payments',
    MANAGE_ACCESS: 'manage-access',
    AUDIT_LOG: 'audit-log',
  },
  WATCHLIST: {
    DETAILS: 'details/:trademarkIrn',
    SUMMARY: 'summary',
    DESIGNATION: 'designation',
    TRANSACTIONS: 'transactions',
    CERTIFIED_DOCUMENTS: 'certified-documents',
    PAYMENTS: 'payments',
    MANAGE_ACCESS: 'manage-access',
    AUDIT_LOG: 'audit-log',
  },
} as const;
