<div
  class="tw-border tw-border-aqua-5 tw-bg-aqua-11 tw-px-[29px] tw-m-[1px] tw-flex tw-justify-between tw-items-center tw-h-[70px]">
  <div class="tw-flex tw-gap-2 tw-items-center">
    <span>{{ 'CUSTOMER_SUPPORT.BAR.LABEL' | translate }}:</span>
    <span class="tw-font-bold">{{ customerSupportEmail }}</span>
  </div>

  <button
    w-button
    [label]="'CUSTOMER_SUPPORT.BAR.END_SESSION' | translate"
    [isDefault]="true"
    size="small"
    (click)="onEndSession()"
    class="!tw-m-0"></button>
</div>
