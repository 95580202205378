import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { FindMonitMonitorFiltersActions } from '../actions/findmonit-monitor-filters.actions';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { BaseFiltersState } from '@store/sidebar-filters/base-filters.state';

export interface FindMonitMonitorFiltersStateModel {
  filtersSections: IFilterSection[];
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  filtersSidebarCollapsedState: boolean;
  selectedFilters: IFilterSelection;
  trademarkIRNsList: string[];
}

@State<FindMonitMonitorFiltersStateModel>({
  name: 'filtersMonitor',
  defaults: {
    filtersSections: [],
    filtersSectionsCollapsedState: {},
    filtersSidebarCollapsedState: false,
    selectedFilters: {},
    trademarkIRNsList: [],
  },
})
@Injectable()
export class FindMonitMonitorFiltersState extends BaseFiltersState<FindMonitMonitorFiltersStateModel> {
  @Selector()
  public static getTrademarksIRNs(state: FindMonitMonitorFiltersStateModel): string[] {
    return state.trademarkIRNsList;
  }

  /**
   * An object containing properties that correspond to each filter section
   * and each obj property holds an array of strings representing the user selection
   * @param state
   * @returns
   */
  @Selector()
  public static getSelectedFilters(state: FindMonitMonitorFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  /**
   * Whenever the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetFiltersSidebarCollapsed)
  public override setFiltersSidebarCollapsed(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetFiltersSidebarCollapsed,
  ): void {
    super.setFiltersSidebarCollapsed(context, action);
  }

  /**
   * Whenever the user changes the collapse states of the section, we keep track of these changes in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetSectionCollapsed)
  public override setSectionCollapsed(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetSectionCollapsed,
  ): void {
    super.setSectionCollapsed(context, action);
  }

  /**
   * When we set the filters sections in the states we also check & update the collapsed states for them
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetFiltersSections)
  public override setFiltersSections(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetFiltersSections,
  ): void {
    super.setFiltersSections(context, action);
  }

  /**
   * Update the selected filters after we remove a tag
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.RemoveTag)
  public override removeTag(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.RemoveTag,
  ): void {
    super.removeTag(context, action);
  }

  @Action(FindMonitMonitorFiltersActions.SetQuickFilterSelection)
  @Action(FindMonitMonitorFiltersActions.SetQuickFilterSelection)
  public override setQuickFilterSelection(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetQuickFilterSelection,
  ): void {
    super.setQuickFilterSelection(context, action);
  }

  /**
   * Whenever the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetTrademarksIRNs)
  public setTrademarksIRNs(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetTrademarksIRNs,
  ): void {
    context.setState(
      patch<FindMonitMonitorFiltersStateModel>({
        trademarkIRNsList: action.trademarkIRNs,
      }),
    );
  }

  @Action(FindMonitMonitorFiltersActions.SetSelectedFilters)
  @Action(FindMonitMonitorFiltersActions.SetSelectedFilters)
  public override setSelectedFilters(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetSelectedFilters,
  ): void {
    super.setSelectedFilters(context, action);
  }
}
