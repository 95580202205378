import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(private messageService: MessageService) {}

  showSuccess(title: string, message: string): void {
    this.showCustomToast('success', title, message);
  }

  showError(title: string, message: string): void {
    this.showCustomToast('error', title, message);
  }

  showInfo(title: string, message: string): void {
    this.showCustomToast('info', title, message);
  }

  showWarning(title: string, message: string): void {
    this.showCustomToast('warn', title, message);
  }

  private getStyleClass(severity: string): string {
    return `custom-toast custom-toast-${severity}`;
  }

  showCustomToast(
    severity: 'success' | 'info' | 'warn' | 'error',
    title: string,
    message: string,
  ): void {
    this.messageService.add({
      severity: severity,
      summary: title,
      detail: message,
      styleClass: this.getStyleClass(severity),
    });
  }
}
