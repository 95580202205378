import { ActiveLinkDirective } from '@directives/active-link/active-link.directive';
import { AppComponent } from './app.component';
import { AppInitializer } from '@services/app-initializer/app-initializer';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from '@services/interceptors/auth-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { EMadridUserService } from '@services/emadrid-user/emadrid-user.service';
import { environment } from 'src/environments/environment';
import { FFacetModule, WAngularIndependentModule, WInputSearchModule } from '@wipo/w-angular';
import { FindMonitMonitorState } from '@store/findmonit-monitor/states/findmonit-monitor.state';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ManagePortfoliosState } from '@store/manage-portfolios/states/manage-portfolios.state';
import { ManageTrademarksState } from '@store/manage-trademarks/states/manage-trademarks.state';
import { ManageTransactionsState } from '@store/manage-transactions/states/manage-transactions.state';
import { MockEMadridUserService } from '@services/emadrid-user/m-emadrid-user.service';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { OAuthModule } from 'angular-oauth2-oidc';
import { PrimengAllModule } from '@wipo/w-angular/primeng';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { TmDetailsState } from '@store/trademark-details/states/tm-details.state';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WipoAuthService } from '@services/auth/wipo-auth.service';
import { AuthState } from '@store/auth-state/states/auth-state.state';
import { UnauthenticatedState } from '@store/unauthenticated-state/states/unauthenticated.state';
import { WatchlistState } from '@store/findmonit-watchlist/states/watchlist.state';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ManageTmsFiltersState } from '@store/sidebar-filters/states/manage-trademarks-filters.state';
import { CustomerSupportBarComponent } from './modules/e-madrid/customer-support-bar/customer-support-bar.component';
import { ManageTransactionsFiltersState } from '@store/sidebar-filters/states/manage-transactions-filters.state';
import { PaidPaymentsFiltersState } from '@store/sidebar-filters/states/paid-payments-filters.state';
import { UnpaidPaymentsFiltersState } from '@store/sidebar-filters/states/unpaid-payments-filters.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot([
      AuthState,
      ManageTrademarksState,
      ManageTmsFiltersState,
      ManageTransactionsState,
      ManageTransactionsFiltersState,
      UnauthenticatedState,
      PaidPaymentsFiltersState,
      UnpaidPaymentsFiltersState,
    ]),
    NgxsStoragePluginModule.forRoot({
      key: [
        FindMonitMonitorState,
        ManagePortfoliosState,
        ManageTrademarksState,
        ManageTransactionsState,
        TmDetailsState,
        UnauthenticatedState,
        WatchlistState,
        PaidPaymentsFiltersState,
        UnpaidPaymentsFiltersState,
      ],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'eMadrid UI',
      disabled: environment.production,
    }),
    NgxsResetPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: AppInitializer.createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    WAngularIndependentModule,
    PrimengAllModule,
    BrowserAnimationsModule,
    FFacetModule,
    WInputSearchModule,
    ActiveLinkDirective,
    ToastModule,
    CustomerSupportBarComponent,
  ],
  providers: [
    MessageService,
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      // Intializing authentication service at the time of application start-up
      useFactory: (wipoAuthService: WipoAuthService) => () => wipoAuthService.initialize(),
      deps: [WipoAuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => () => appInitializer.initializeAppFactory(),
      multi: true,
      deps: [AppInitializer],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: EMadridUserService,
      useClass: environment.mockService ? MockEMadridUserService : EMadridUserService,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TranslateModule],
})
export class AppModule {}
