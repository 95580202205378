import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TmDetailsActions } from '../actions/tm-details.action';
import { ITrademark } from '@interfaces/trademarks/trademark.interface';
import { CdDynamicFormState } from '@store/certified-documents-dynamic-form/states/cd-dynamic-form.state';
import { IStakeholder } from '@interfaces/common/stakeholder.interface';

export interface TmDetailsStateModel {
  trademark: Partial<ITrademark>;
}

@State<TmDetailsStateModel>({
  name: 'trademarkDetails',
  defaults: {
    trademark: {},
  },
  children: [CdDynamicFormState],
})
@Injectable()
export class TmDetailsState {
  @Selector()
  public static getTmDetailsIrn(state: TmDetailsStateModel): string | undefined {
    return state.trademark.irn;
  }

  @Selector()
  public static getTmDetailsHolders(state: TmDetailsStateModel): IStakeholder[] | undefined {
    return state.trademark.holders;
  }

  @Selector()
  public static getTmDetailsRelationship(state: TmDetailsStateModel): string | undefined {
    return state.trademark.relationship;
  }

  @Selector()
  public static getTmExpirationDate(state: TmDetailsStateModel): Date | undefined {
    return state.trademark.expirationDate;
  }

  @Action(TmDetailsActions.SetTmDetails)
  public setTmDetails(context: StateContext<TmDetailsStateModel>, actionParams: any): void {
    const state = context.getState();
    const updatedTmDetails = actionParams.trademark;

    const mergedTmDetails = {
      ...state.trademark,
      ...updatedTmDetails,
    };

    context.setState({
      ...state,
      trademark: mergedTmDetails,
    });
  }
}
