import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AuthStateActions as AuthStateActions } from '../actions/auth-state.action';

export interface AuthStateModel {
  isUserLoggedIn: boolean;
  userEmail: string | null;
  customerSupportEmail: string | null;
}

@State<AuthStateModel>({
  name: 'authState',
  defaults: {
    isUserLoggedIn: false,
    userEmail: null,
    customerSupportEmail: null,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  public static isUserLoggedIn(state: AuthStateModel): boolean {
    return state.isUserLoggedIn;
  }

  @Selector()
  public static getUserEmail(state: AuthStateModel): string | null {
    return state.userEmail;
  }

  @Selector()
  public static getCustomerSupportEmail(state: AuthStateModel): string | null {
    return state.customerSupportEmail;
  }

  @Action(AuthStateActions.SetUserLoggedIn)
  public setUserLoggedIn(
    ctx: StateContext<AuthStateModel>,
    action: AuthStateActions.SetUserLoggedIn,
  ): void {
    ctx.patchState({
      isUserLoggedIn: action.isLoggedIn,
    });
  }

  @Action(AuthStateActions.SetUserEmail)
  public setUserEmail(
    ctx: StateContext<AuthStateModel>,
    action: AuthStateActions.SetUserEmail,
  ): void {
    ctx.patchState({
      userEmail: action.userEmail,
    });
  }

  @Action(AuthStateActions.SetCustomerSupportEmail)
  public setCustomerSupportEmail(
    ctx: StateContext<AuthStateModel>,
    action: AuthStateActions.SetCustomerSupportEmail,
  ): void {
    ctx.patchState({
      customerSupportEmail: action.customerEmail,
    });
  }

  @Action(AuthStateActions.RemoveCustomerSupportEmail)
  public removeCustomerSupportEmail(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      customerSupportEmail: '',
    });
  }
}
