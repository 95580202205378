import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  WIPO_LANGUAGE_COOKIE = 'wipo_language'; // WIPO language standard cookie name
  WIPO_LANGUAGE_COOKIE_DOMAIN = environment.cookieDomain;
  supportedLanguages = ['en', 'fr', 'es']; // Supported language list
  defaultLanguage = 'en'; // Default language
  currentLanguage: string = this.defaultLanguage;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
  ) {}

  public initialLanguageSetup() {
    this.translate.addLangs(this.supportedLanguages);
    this.translate.setDefaultLang(this.defaultLanguage);
    const cookieLang = this.cookieService.get(this.WIPO_LANGUAGE_COOKIE);

    if (cookieLang && this.isLanguageSupported(cookieLang)) {
      this.currentLanguage = cookieLang;
    } else {
      this.currentLanguage = this.defaultLanguage;
    }

    this.changeLanguage();
  }

  public changeLanguage() {
    // Reset language
    const languages = this.translate.getLangs();
    languages.forEach((lang) => {
      this.translate.resetLang(lang);
    });
    this.cookieService.set(
      this.WIPO_LANGUAGE_COOKIE,
      this.currentLanguage,
      undefined,
      '/',
      this.WIPO_LANGUAGE_COOKIE_DOMAIN,
    );
    this.translate.use(this.cookieService.get(this.WIPO_LANGUAGE_COOKIE));
  }

  public isLanguageSupported(lang: string): boolean {
    return this.supportedLanguages.indexOf(lang) !== -1;
  }
}
